body {
	font-family: Roboto, sans-serif;
	color: #444;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 400;
}

.page-wrapper-full {
	color: #444444;
	display: block;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 2.75em;
	line-height: 3.45rem;
	font-weight: 700;
	text-transform: uppercase;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.9rem;
	line-height: 2.4rem;
	font-weight: 700;
}

h3 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 700;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: 700;
}

h6 {
	margin-top: 1.25em;
	margin-bottom: 1em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

body a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #163a59;
	background-color: transparent;
	text-decoration: none;
}

body a:hover {
	color: #000;
	background-color: transparent;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem 1.5rem;
	border-left: 5px solid #5c7f06;
	background-color: #f9f9f9;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.9rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-right: 1.25rem;
	padding: 0.65rem 1.5rem;
	background-color: #163a59;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 0.65rem 1.5rem !important;
	border: 0 none !important;
}

body a.primary,
body a.secondary {
	color: #fff !important;
	cursor: pointer;
}

.button:hover {
	background-color: #3e3f3d;
	color: #fff;
	cursor: pointer;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	background-color: #3e3f3d !important;
	color: #fff;
	cursor: pointer;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	background-color: #5b636b;
	cursor: pointer;
}

.button.secondary:hover {
	background-color: #3e3f3d;
	cursor: pointer;
}

.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #3e3f3d !important;
	cursor: pointer;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	background-color: #acadaf;
	color: rgba(0, 0, 0, 0.7);
	padding: 0.5rem 1rem 0.35rem !important;
}

.button.tertiary:hover {
	background-color: #3e3f3d;
	color: #fff;
	cursor: pointer;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #3e3f3d !important;
	color: #fff;
	cursor: pointer;
}

.button.footer-button {
	margin-top: 0.25em;
	padding-bottom: 0.625em;
	background-color: #163a59;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1em;
	line-height: 1.25em;
}

.button.footer-button:hover {
	background-color: #000;
	cursor: pointer;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	width: 2.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-radius: 0px;
	background-color: #fff;
	background-image: url('/20170714042655/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	opacity: 0.65;
	color: transparent;
	font-size: 0.875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.header-search-form {
	position: relative;
}

.button.header-search-button {
	position: absolute;
	right: 0rem;
	height: 2.25rem;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.button.add-to-cart {
	max-width: 65%;
	margin-right: 0.5em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.button.slider-button {
	position: relative;
	margin-top: 0.625em;
	margin-bottom: 0em;
	padding: 0.5rem 1rem 0.38rem 3.125rem;
	background-color: hsla(0, 0%, 100%, 0.65);
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.slider-button:hover {
	padding-left: 3.5rem;
	background-color: hsla(0, 0%, 100%, 0.8);
	color: #3e3f3d;
}

.button.bill-tracker-button {
	position: relative;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding: 0.5rem 1.75rem 0.38rem 3.125rem;
	background-color: #5c7f06;
	background-image: url("../images/white-arrow-01.svg");
	background-position: 95% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.bill-tracker-button:hover {
	padding-right: 2.25rem;
	padding-left: 3.125rem;
	background-color: #4e6d01;
	color: #fff;
}

.container {
	position: relative;
	max-width: 1200px;
	padding-right: 25px;
	padding-left: 25px;
}

.nav-section {
	background-color: #ffffff;
	position: fixed;
	top: 0px;
	width: 100%;
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 1.25rem 0.25rem 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0em;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #5c7f06, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 0 0 #5c7f06, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.w--current:hover {
	background-color: rgba(0, 0, 0, 0.35);
	box-shadow: 0 -5px 0 0 #5c7f06, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px solid hsla(0, 0%, 100%, 0.5);
	margin: 0;
	padding: 0;
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1em;
	margin-left: 0.375em;
	padding-right: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom-style: none;
	color: #000;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-style: italic;
	font-weight: 700;
}

.logo:hover {
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
}

.footer-section {
	position: relative;
	border-top: 10px solid #5c7f06;
	background-color: #fafafa;
	color: #3e3f3d;
	font-size: 0.9375rem;
	line-height: 1.625em;
}

.row.shop-detail-row {
	margin-bottom: 1.5em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.home-cbox-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 4em;
}

.column.shop-detail-image-col {
	padding-right: 20px;
}

.column.footer-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.warning-p {
	margin-bottom: 0;
	text-align: left;
}

.column.footer-signoff-column.right-signoff-column {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.logo-image {
	width: 240px;
	float: left;
	transition: all 300ms ease;
}

.footer-signoff-section {
	padding-bottom: 1.5rem;
	background-color: transparent;
	font-size: 0.8rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
}

.copyright-text h1 {
	display: inline-block;
	font-family: Roboto, sans-serif;
	color: #3e3f3d;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
	white-space: nowrap;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-top: 0.125em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	margin-top: 2px;
	float: right;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 0.5rem;
	line-height: 0.5625rem;
	font-weight: 600;
	text-transform: uppercase;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

.footer-signoff-grip:hover {
	color: #606060;
	cursor: pointer;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 50%;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	padding-right: 1rem;
	clear: both;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.footer-column-wrap.footer-social-column {
	max-width: 200px;
}

.footer-column-wrap.footer-updates-column {
	max-width: 45%;
	margin-top: -0.75rem;
	margin-bottom: -0.75rem;
	padding-top: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0.4)), color-stop(95%, hsla(0, 0%, 100%, 0)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.4) 60%, hsla(0, 0%, 100%, 0) 95%);
	box-shadow: 0 -10px 0 0 rgba(0, 0, 0, 0.5);
}

.footer-column-title {
	margin-top: 0.5em;
	margin-bottom: 0.375em;
	padding-top: 0.125em;
	color: #163a59;
	font-size: 1.375em;
	line-height: 1.25em;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.footer-column-title.footer-notifications-title {
	color: #163a59;
	font-size: 1.4125em;
	line-height: 1.25em;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -1px;
	margin-right: 0em;
	padding-bottom: 1rem;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: relative;
	border-top: 7px solid #5c7f06;
	background-color: transparent;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1.5rem;
	padding-left: 0px;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: #163a59;
	display: block;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-wrap.pagination-sort {
	position: relative;
	display: inline-block;
	text-align: left;
}

.form.sort-by-form {
	position: relative;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.sort-by-dropdown {
	height: 2.25rem;
	margin-bottom: 0em;
	padding-right: 6px;
	padding-left: 6px;
}

.form-field.header-search-field {
	width: 120px;
	height: 2.25rem;
	margin-bottom: 0em;
	border-style: none;
	border-width: 0px;
	border-color: rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #3e3f3d;
	font-size: 0.75em;
}

.open-dropdown-menu .form-field.header-search-field,
.form-field.header-search-field:focus {
	width: 160px;
	border: 0px none transparent;
	background-color: #fff;
}

.form-field.header-search-field::-webkit-input-placeholder {
	color: #797676;
}

.form-field.header-search-field:-ms-input-placeholder {
	color: #797676;
}

.form-field.header-search-field::-ms-input-placeholder {
	color: #797676;
}

.form-field.header-search-field::placeholder {
	color: #797676;
}

.form-field.shop-detail-form-field {
	width: 100%;
	margin-bottom: 0.25em;
}

.form-success {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1.25rem;
	background-color: #1f8927;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-right: 0.75rem;
	margin-bottom: 0rem;
	margin-left: 0.75rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-item.first {
	margin-left: 0rem;
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-column-container {
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.left-nav-list-item {
	margin-bottom: 0;
}

.left-nav-list-link {
	display: block;
	padding: 0.6rem 0.75rem 0.6rem 0.5rem;
	border-left: 3px solid transparent;
	color: #444;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 400;
	border-bottom: 1px solid #e6e6e6;
}

.left-nav-list-link:hover {
	border-left-color: #163a59;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #163a59;
}

.left-nav-list .left-nav-list-item:first-of-type .left-nav-list-link {
	border-top: 1px solid #e6e6e6;
}

.left-nav-list-link.selected {
	border-left-color: #5c7f06;
	background-color: #f9f9f9;
	color: #163a59;
}

.left-nav-nested-list {
	overflow: hidden;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0px 0em 0px 0.75em;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.5rem 1.25rem 0.4375em 0.5rem;
	border-left: 2px solid transparent;
	color: #444;
	font-size: 0.875rem;
	line-height: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.03em;
	border-bottom: 1px solid #e6e6e6;
}

.left-nav-nested-list-link:hover {
	border-left-color: #000;
	color: #163a59;
	-webkit-transform: translate(5px, 0px) ;
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
}

.left-nav-nested-list-link.selected {
	background-color: #f9f9f9;
	border-left-color: #5c7f06;
	color: #163a59;
}

.left-nav-nested-list-item .left-nav-nested-list-item .left-nav-nested-list-link.selected {
	background-color: #f9f9f9;
	cursor: pointer;
}

.left-nav-list-link.selected:hover,
.left-nav-nested-list-link.selected:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.right-sidebar-column .box {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 3px;
	padding-left: 3px;
	border-top: 2px solid #5c7f06;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-sidebar-column h1,
.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4,
.right-sidebar-column h5,
.right-sidebar-column h6 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	color: #253b91;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.right-sidebar-column .contentbox_item .contentbox_item_image {
	width: 32px;
}

.sidebar-list {
	padding-left: 0px;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.right-sidebar-image-example {
	width: 100%;
	max-width: 414px;
	margin-right: 0.75em;
	margin-bottom: 0.75em;
	float: left;
	clear: both;
	background-color: #dedede;
}

.section-wrap.inside-content-section {
	background-color: rgba(127, 168, 189, 0.2);
	background-image: url("/20170714042655/assets/images/lined-paper-wht.png");
	background-position: 0px 0px;
	background-size: 150px 112px;
	margin-top: 244px;
}

.secondary-nav-icon {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #5b636b;
}

.secondary-nav-icon.idea-icon {
	width: 2em;
	height: 2em;
	margin-right: 0.4125em;
	padding: 7px 7px 10px;
	border-radius: 50%;
	background-color: #163a59;
}

.secondary-nav-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.secondary-nav-text.phone-text {
	padding-top: 0.125em;
}

.secondary-nav-text.indicator-text {
	position: relative;
	min-width: 2em;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: none;
	font-size: 0.875em;
	line-height: 1.5em;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: rgba(0, 0, 0, 0.75);
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	transform: translate(0px, -3px);
	color: #64802c;
}

.slider-section {
	position: relative;
	margin-top: 244px;
	background-color: #f8f9fa;
	border-top: 5px solid #fff;
}

.slider {
	display: block;
	height: 45vw;
	max-height: 540px;
	max-width: 100%;
	min-height: 150px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	top: 50%;
	bottom: auto;
	width: 5rem;
	height: 6rem;
	background-color: transparent;
	opacity: 0.55;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #83888d;
	font-size: 3rem;
}

.slider-arrow:hover {
	background-color: #163a59;
	opacity: 1;
	color: #fff;
}

.slider-arrow:focus {
	background-color: transparent;
	opacity: 1;
	color: #163a59;
}

.slider-indicator {
	overflow: hidden;
	height: 0.25em;
	margin-bottom: 1em;
	padding-top: 0rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 1.5rem;
	line-height: 0.5em;
}

.home-section {
	background-color: #f3f5f7;
	display: block;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 25vw;
	max-height: 320px;
	min-height: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px solid #163a59;
	background-color: #f3f5f7;
	background-image: url('/20170714042655/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link:hover {
	opacity: 0.85;
}

.home-image-link.large-image-link {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 35%;
	-ms-flex-preferred-size: 35%;
	flex-basis: 35%;
	border-top-color: #5c7f06;
}

.home-content-box-wrap.intro-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.home-content-box-title {
	margin-top: 0rem;
	margin-bottom: 0.25em;
	padding-top: 0.125em;
	color: #163a59;
	font-size: 1.625em;
	line-height: 1.125em;
	text-transform: uppercase;
}

.home-content-box-title.hero-cbox-title {
	font-size: 1.75em;
}

.home-content-box-title.intro-cbox-title {
	padding-top: 0.125em;
	font-size: 2.25em;
	line-height: 1.125em;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.home-cbox-learn-more {
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid transparent;
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.home-cbox-learn-more:hover {
	border-bottom: 1px solid #797676;
	color: #3e3f3d;
}

.home-cbox-learn-more.intro-cbox-learn-more {
	padding: 0.65rem 1.5rem 0.55rem;
	border-bottom-style: none;
	background-color: #fff;
	font-size: 1em;
	line-height: 0.8em;
	font-weight: 600;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.home-cbox-learn-more.intro-cbox-learn-more:hover {
	border-bottom-style: none;
	background-color: #3e3f3d;
	color: #fff;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-caption-wrap {
	position: relative;
	top: 50%;
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 2rem;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	color: #fff;
	text-align: left;
}

.shop-item-wrap {
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.shop-item-wrap.flex {
	width: 31.33%;
	margin-right: 1%;
	margin-left: 1%;
}

.shop-item-wrap.flex.item-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 98%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-bottom: 1px solid #e6e6e6;
}

.product-image-link {
	display: block;
	margin-bottom: 0.75em;
	padding: 0px;
	text-align: center;
}

.product-image-link.list-view-link {
	max-width: 25%;
}

.product-info-image {
	display: inline-block;
}

.product-info-title {
	margin-top: 0.5em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.product-info-abstract {
	font-size: 0.875em;
	line-height: 1.5em;
}

.product-info-price,
#products_module.responsive .items .item-wrapper .item .item-description .item-price {
	display: inline-block;
	margin-right: 0.5em;
	font-size: 1em;
	line-height: 1.25em;
	font-weight: 600;
	text-transform: uppercase;
}

.product-info-price.old-price {
	color: #a3a3a3;
	text-decoration: line-through;
}

.product-info-price.sale-price {
	color: #bf6034;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5em;
	padding-bottom: 0em;
	border-bottom: 1px solid #e6e6e6;
}

.inside-page-header-section {
	position: relative;
	height: 80px;
	background-color: #112d45;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.22)), to(rgba(0, 0, 0, 0.22)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22));
	color: #3e3f3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1280px;
	margin-top: -7px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1.5rem;
	padding-bottom: 4rem;
	border-top: 7px solid #5c7f06;
	background-color: #fff;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.intro-text-style,
.intro_text_style {
	color: #444;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 400;
	display: block;
}

.home-cbox-text-wrap {
	padding-top: 1.125em;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.hero-cbox-text-wrap {
	font-size: 1.25rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.intro-cbox-text-wrap {
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Roboto, sans-serif;
	color: hsla(0, 0%, 100%, 0.85);
	font-size: 1.5rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.intro-cbox-text-wrap p {
	font-family: Roboto, sans-serif;
	font-weight: 400;
}

.slider-caption-title {
	display: inline;
	padding-top: 0.125em;
	padding-bottom: 0px;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsla(0, 0%, 100%, 0.65), -20px 0 0 0 hsla(0, 0%, 100%, 0.65);
	font-family: 'Roboto Condensed', sans-serif;
	color: #163a59;
	font-size: 3.5rem;
	line-height: 5.75rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: uppercase;
}

.footer-social-icons-wrap {
	margin-bottom: 1em;
	padding-left: 1px;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	opacity: 0.7;
}

.footer-social-icon-link:hover {
	border-color: rgba(0, 0, 0, 0.2);
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(127, 168, 189, 0.04)), to(rgba(127, 168, 189, 0.04)));
	background-image: linear-gradient(180deg, rgba(127, 168, 189, 0.04), rgba(127, 168, 189, 0.04));
	opacity: 1;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-social-icon-link:focus {
	border-color: #000;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 2px;
}

.footer-newsletter-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.home-cbox-dynamic-feed-item-wrap {
	margin-bottom: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid rgba(40, 49, 59, 0.15);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.home-cbox-dynamic-feed-date {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.home-cbox-dynamic-feed-item-title {
	margin-top: 0em;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
}

.footer-link {
	color: #3e3f3d;
	font-size: 0.95rem;
}

.footer-link:hover {
	box-shadow: 0 1px 0 0 #163a59;
	color: #000;
}

.footer-link:focus {
	box-shadow: 0 1px 0 0 #163a59;
}

.footer-link.signoff-link {
	font-size: 0.8rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-link:hover {
	box-shadow: 0 1px 0 0 #163a59;
	color: #000;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #3e3f3d;
	line-height: 1.15rem;
	text-transform: capitalize;
}

.footer-quick-link.w--current {
	border-bottom-color: #797676;
}

.footer-quick-link:hover {
	border-bottom: 1px solid #797676;
}

.link,
.links-wrap a,
.right-sidebar-column a {
	border-bottom: 1px solid #797676;
	color: #3e3f3d;
	font-weight: 500;
}

.link:hover,
.links-wrap a:hover,
.right-sidebar-column a:hover {
	border-bottom-color: transparent;
}

.links-wrap h1 a,
.links-wrap h2 a,
.links-wrap h3 a,
.links-wrap h4 a,
.links-wrap h5 a,
.links-wrap h6 a,
.left-sidebar-custom h1 a,
.left-sidebar-custom h2 a,
.left-sidebar-custom h3 a,
.left-sidebar-custom h4 a,
.left-sidebar-custom h5 a,
.left-sidebar-custom h6 a,
.right-sidebar-column h1 a,
.right-sidebar-column h2 a,
.right-sidebar-column h3 a,
.right-sidebar-column h4 a,
.right-sidebar-column h5 a,
.right-sidebar-column h6 a {
	border-bottom: 1px solid #797676;
	font-weight: inherit;
	color: #3e3f3d;
}

.links-wrap h1 a:hover,
.links-wrap h2 a:hover,
.links-wrap h3 a:hover,
.links-wrap h4 a:hover,
.links-wrap h5 a:hover,
.links-wrap h6 a:hover,
.left-sidebar-custom h1 a:hover,
.left-sidebar-custom h2 a:hover,
.left-sidebar-custom h3 a:hover,
.left-sidebar-custom h4 a:hover,
.left-sidebar-custom h5 a:hover,
.left-sidebar-custom h6 a:hover,
.right-sidebar-column h1 a:hover,
.right-sidebar-column h2 a:hover,
.right-sidebar-column h3 a:hover,
.right-sidebar-column h4 a:hover,
.right-sidebar-column h5 a:hover,
.right-sidebar-column h6 a:hover {
	border-bottom-color: transparent;
	color: #3e3f3d;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.content-box-title-link {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #163a59;
}

.link.content-box-title-link:hover {
	border-bottom-color: #797676;
	color: #3e3f3d;
}

.link.content-box-title-link.intro-cbox-title-link {
	display: inline-block;
	padding-top: 0.125em;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #fff;
}

.link.content-box-title-link.intro-cbox-title-link:hover {
	border-bottom-color: transparent;
	color: #fff;
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.link.breadcrumb-link {
	display: inline-block;
	color: #797676;
	font-weight: 400;
}

.link.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #3e3f3d;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select,
.pagination-wrapper select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 6px 10px;
	height: 2.25rem;
}

.pagination-wrap,
.pagination-wrapper {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.pagination-wrap.flex-pagination,
.pagination-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.5em;
	padding-right: 1%;
	padding-left: 1%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #e6e6e6;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.shop-item-text-wrap.list-view-text-wrap {
	margin-left: 1em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	border-bottom: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
	cursor: pointer;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-left-side-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.pagination-view-by-icons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0.5rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.pagination-middle-group-wrap {
	display: inline-block;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid hsla(0, 0%, 100%, 0.5);
	margin: 0;
	transition: all 0.3s ease;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
}

.nav-drop-wrap:hover .nav-drop-toggle-link {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #5c7f06;
}

.dropdown-ul {
	position: absolute;
	left: 0;
	min-width: 100%;
	height: inherit;
	overflow: hidden;
	padding: 0;
	margin: 0;
	top: 100%;
	max-height: 0;
	transition: max-height 0.25s ease 0s;
	background-color: #102b43;
}

.nav-drop-wrap:last-of-type .dropdown-ul {
	left: auto;
	right: 0;
}

.open .dropdown-ul {
	max-height: 1000px;
	transition: max-height 1s ease 0s;
}

.nav-drop-icon {
	margin-right: 1rem;
	font-size: 0.6875em;
}

.nav-drop-list {
	overflow: hidden;
	display: block;
}

.nav-drop-wrap:last-of-type .nav-drop-list {
	right: 0;
	left: auto;
}

.nav-drop-toggle:hover .nav-drop-list {
	padding: 0.25rem 0em;
}

.nav-drop-list.open-left.w--open {
	background-color: #163a59;
	left: auto;
	right: 0px;
}

.dropdown-ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	background-color: #102b43;
}

.dropdown-ul li {
	margin: 0;
}

.dropdown-ul a,
.nav-drop-list-link {
	margin-bottom: 0.125rem;
	padding: 0.75em 2rem 0.75em 0.75rem;
	color: #c3cad0;
	font-size: 0.875rem;
	line-height: 1.25em;
	font-weight: 400;
	display: block;
	white-space: nowrap;
}

.dropdown-ul a:hover,
.nav-drop-list-link:hover {
	background-color: #294256;
	color: #fff;
	cursor: pointer;
}

.dropdown-ul a.w--current,
.nav-drop-list-link.w--current {
	box-shadow: inset 3px 0 0 0 #294256;
	color: #fff;
	cursor: pointer;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 1.25rem 0rem 1rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0em;
	text-transform: uppercase;
}

.nav-drop-toggle-link:hover {
	color: #fff;
	cursor: pointer;
}

.nav-drop-toggle-link.active-item,
.nav-drop-toggle-link.w--current {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 -5px 0 0 #5c7f06;
	color: #fff;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1170px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.pagination-view-by-icon-image {
	padding: 4px;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.shop-first-heading {
	margin-top: 0em;
}

.shop-header-footer-wrap {
	margin-bottom: 1.5em;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	margin-left: 1rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: Roboto, sans-serif;
	color: #3e3f3d;
	font-size: 1.25rem;
	line-height: 1em;
	font-weight: 700;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.secondary-header-item-wrap:hover {
	color: #163a59;
	cursor: pointer;
}

.header-content-right-row {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.bill-tracker-icon {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2.25rem;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid hsla(0, 0%, 100%, 0.1);
	background-color: transparent;
	background-image: url("../images/capitol-building-01.svg");
	background-position: 50% 40%;
	background-size: 26px 26px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.85rem;
}

.secondary-header-item-cta-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-left: 1rem;
	padding: 0.75em 1.5rem 0.61em 3em;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: #3e3f3d;
	background-image: url('/20170714042655/assets/images/speech-bubble.svg');
	background-position: 21px 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	font-family: Roboto, sans-serif;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: #163a59;
	color: #fff;
	cursor: pointer;
}

.secondary-header-item-cta-link:focus {
	background-color: #3e3f3d;
	cursor: pointer;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 1.25rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
	overflow: hidden;
}

.shop-detail-tabs-wrap {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.shop-detail-related-items-title {
	margin-top: 0em;
}

.shop-detail-related-items-wrap {
	padding-top: 1.5em;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.shop-detail-tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.shop-detail-tab-link {
	margin-right: 0.5%;
	padding: 0.75em 0.75rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #e6e6e6;
	color: #000;
	font-weight: 500;
}

.shop-detail-tab-link:hover {
	color: #163a59;
	cursor: pointer;
}

.shop-detail-tab-link.w--current {
	background-color: #163a59;
	color: #fff;
	cursor: pointer;
}

.shop-detail-tabs-content {
	padding: 1em 0.75rem;
}

.shop-detail-attributes-wrap {
	margin-bottom: 1.5em;
	padding: 1em;
	border: 1px solid #e6e6e6;
}

.shop-detail-lightbox-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #e6e6e6;
	text-align: center;
}

.shop-detail-text.product-price {
	font-size: 1.5em;
	line-height: 1.25em;
}

.shop-detail-form-section {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	padding-top: 0.5em;
	border-top: 1px solid #e6e6e6;
}

.shop-detail-form-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.slider-caption-title-wrap {
	margin-bottom: 0;
	padding-top: 4px;
	padding-right: 20px;
	padding-left: 20px;
}

.slider-caption-all {
	padding-left: 20px;
}

.slider-caption-heading,
.slider-caption-all h1,
.slider-caption-all h2,
.slider-caption-all h3,
.slider-caption-all h4,
.slider-caption-all h5,
.slider-caption-all h6,
.slider-caption-all p {
	display: inline-block;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	padding-top: 0.6em;
	padding-bottom: 0.6rem;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsla(0, 0%, 100%, 0.65), -20px 0 0 0 hsla(0, 0%, 100%, 0.63);
	font-family: Roboto, sans-serif;
	color: #163a59;
	font-size: 1.4rem;
	line-height: 1.4rem;
	font-weight: 500;
	letter-spacing: 0rem;
	text-shadow: none;
}

.home-content-box-section-wrap {
	padding-top: 3em;
	padding-bottom: 2em;
	background-color: rgba(127, 168, 189, 0.2);
	background-image: url("/20170714042655/assets/images/lined-paper-wht.png");
	background-position: 0px 0px;
	background-size: 150px 112px;
}

.slider-button-arrow-span {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2.25rem;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	background-color: hsla(0, 0%, 100%, 0.4);
	background-image: url('/20170714042655/assets/images/icon_arrow2_right_blk_50.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.85em;
}

.intro-cbox-section {
	margin-top: 4em;
	margin-bottom: 3em;
	padding-top: 3em;
	padding-bottom: 3em;
	background-color: #e4e7eb;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 58, 89, 0.7)), to(rgba(22, 58, 89, 0.7))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))), url('/20170714042655/assets/images/LisleGroup2013.jpg');
	background-image: linear-gradient(180deg, rgba(22, 58, 89, 0.7), rgba(22, 58, 89, 0.7)), linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/20170714042655/assets/images/LisleGroup2013.jpg');
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
}

.footer-flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.75rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.footer-signoff-acrobat-link {
	margin-right: 2.5em;
}

.acrobat-reader-image {
	width: 135px;
	height: 34px;
}

.slider-image-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.footer-contact-wrap {
	width: 50%;
	padding-right: 1.5rem;
}

.footer-social-block {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.footer-notifications-image {
	width: 6.5vw;
	height: 6.5vw;
	max-height: 4rem;
	max-width: 4rem;
	margin: 1rem 3vw;
	padding: 0px;
	float: left;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.content-image-example {
	width: 300px;
	margin-bottom: 30px;
	margin-left: 30px;
	float: right;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000001;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

.flex-row {
	display: flex;
}

.hero-cbox-wrap,
.home-content-box-wrap {
	height: 100%;
}

.flex-cb-box-text-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 25vw);
	max-height: calc(100% - 140px);
	min-height: calc(100% - 320px);
}

.home-cb-column-half {
	width: calc(50% - 1px);
}

/* partners carousel  */
.home-content-section {
	display: block;
	padding-top: 3rem;
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.sponsors-section {
	padding-top: 2rem;
	padding-bottom: 3rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	background-color: #fff;
}

.container-2 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.cb-head-wrapper {
	display: block;
	margin-bottom: 1rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.clients-cb-head {
	display: block;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	color: #163a59;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

#partners_module .partners-arrow {
	width: 12px;
	height: 12px;
	transition: all 0.3s ease;
}

#partners_module .partners-arrow-div:hover .partners-arrow {
	border-color: #ccc;
}
/* end of partners carousel  */

#GRIPFooterLogoImg {
	filter: brightness(0);
}

/* media queries */
@media (max-width: 991px) {
	h1 {
		font-size: 2.375em;
	}

	.button.header-search-button {
		position: absolute;
		right: 0rem;
		width: 3rem;
		height: 2.875rem;
		opacity: 0.65;
		font-size: 1rem;
	}

	.button.header-search-button:hover {
		opacity: 1;
	}

	.button.slider-button {
		font-size: 0.9375em;
	}

	.container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.footer-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1rem 1rem 0.875rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		font-weight: 500;
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: rgba(0, 0, 0, 0.15);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.15);
		box-shadow: inset 5px 0 0 0 #5c7f06, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: inset 5px 0 0 0 #163a59, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		position: relative;
		padding-left: 2.25rem;
		background-color: rgba(0, 0, 0, 0.25);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		overflow: hidden;
		padding-bottom: 100vh;
		border-left-style: none;
		background-color: #163a59;
		text-align: left;
	}

	.logo {
		margin-top: 0.25em;
		margin-bottom: 0.5em;
		margin-left: 0.75em;
		font-size: 1.25rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row {
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column {
		margin-bottom: 1.5em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.column.footer-signoff-column.right-signoff-column {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.menu-button {
		height: 3.5rem;
		padding: 0px 1.25rem 0px 0.75rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		font-family: Roboto, sans-serif;
		color: #fff;
		line-height: 3.5rem;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	.menu-button.w--open {
		background-color: rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	.menu-button.w--open:hover {
		background-color: rgba(0, 0, 0, 0.5);
		cursor: pointer;
	}

	.footer-signoff-section {
		text-align: center;
	}

	.footer-signoff-grip {
		margin-right: 0.75rem;
		float: none;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		max-width: none;
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.footer-column-wrap.footer-social-column {
		max-width: none;
		padding-right: 0rem;
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
		border-top-style: none;
		text-align: center;
	}

	.footer-column-wrap.footer-updates-column {
		max-width: 100%;
		margin-bottom: -1.5rem;
		padding-bottom: 3rem;
		padding-left: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		border-style: none;
		border-right-width: 1px;
		border-right-color: rgba(0, 0, 0, 0.1);
		border-bottom-width: 1px;
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.header-content-right-wrap {
		margin-right: 0;
		padding-bottom: 0;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slider-button-arrow-span {
		width: 2.7em;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 2.875rem;
		padding-left: 1em;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.8125rem;
	}

	.form-field.header-search-field {
		width: 160px;
		min-width: 160px;
	}

	.open-dropdown-menu .form-field.header-search-field,
	.form-field.header-search-field:focus {
		width: 280px;
		min-width: 280px;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
		border-left-width: 1px;
		border-left-color: rgba(0, 0, 0, 0.25);
	}

	.right-sidebar-column {
		margin-top: 3rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-nav-icon {
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
		background-color: transparent;
	}

	.secondary-nav-icon.idea-icon {
		width: 2.25em;
		height: 2.25em;
		margin-top: 0em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.22rem;
		padding-right: 1.25rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.secondary-nav-list-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		color: #fff;
		transform: translate(0px, 0px);
	}

	.secondary-nav-list-link:active {
		background-color: transparent;
		cursor: pointer;
	}

	.secondary-nav-list-link:focus {
		color: #fff;
		cursor: pointer;
	}

	.slider {
		height: 52vw;
	}

	.slider-arrow {
		top: auto;
		bottom: 0px;
		width: 4rem;
		height: 3rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #e4e7eb;
		font-size: 1.375rem;
	}

	.slider-arrow:hover {
		background-color: transparent;
		cursor: pointer;
	}

	.slider-arrow:active {
		background-color: transparent;
		cursor: pointer;
	}

	.slider-indicator {
		font-size: 1.1875rem;
	}

	.home-image-link {
		height: 25vw;
		max-height: 240px;
	}

	.flex-cb-box-text-wrap {
		min-height: calc(100% - 255px);
		height: calc(100% - 27vw);
	}

	.home-image-link.large-image-link {
		min-height: 220px;
		min-width: 45%;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.home-content-box-wrap.intro-content-box-wrap {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 2.25em;
	}

	.slider-caption-wrap {
		max-width: 90%;
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.inside-content-beckground {
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		padding-top: 0.125em;
		float: left;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		line-height: 3.4125rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
	}

	.home-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		margin-left: 3%;
		padding-top: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 1.0625rem;
		width: 55%;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: 90%;
		font-size: 1.25rem;
	}

	.slider-caption-title {
		font-size: 2.5em;
		line-height: 3rem;
		margin-bottom: 0em;
		padding-top: 0em;
		background-color: transparent;
		box-shadow: none;
		display: block;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.1);
		background-image: url('/20170714042655/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 1.125rem;
		line-height: 1rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #3e3f3d;
	}

	.two-part-drop-list-back-arrow {
		position: absolute;
		left: 1rem;
		top: 50%;
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20170714042655/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		-webkit-transform: translate(0px, -50%);
		-ms-transform: translate(0px, -50%);
		transform: translate(0px, -50%);
		color: transparent;
		line-height: 1rem;
		text-align: center;
	}

	.secondary-header-item-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
		font-size: 1.125rem;
	}

	.header-content-right-row {
		margin-top: 0;
		margin-bottom: 0;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.secondary-header-item-cta-link {
		margin-left: 1.5rem;
		padding-top: 0.8125em;
		padding-bottom: 0.625em;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem 0rem 0px;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-search-form {
		position: relative;
		padding: 0rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.home-content-box-section-wrap {
		padding-top: 2.5em;
		padding-bottom: 1.5em;
	}

	.intro-cbox-section {
		margin-top: 1em;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.footer-flex-row {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-acrobat-link {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.footer-social-block {
		padding-left: 0;
		text-align: left;
	}

	.footer-notifications-image {
		width: 8vw;
		height: 8vw;
		max-height: 5rem;
		max-width: 5rem;
		margin-right: 4vw;
		margin-left: 4vw;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.footer-section a[href^="tel"] {
		display: inline-block !important;
		color: #373a3d;
		font-size: 0.9375rem;
		line-height: 1.625em;
		font-family: Roboto, sans-serif;
	}

	.warning-p {
		text-align: right;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 400px;
	}

	.slider-image {
		position: static;
		height: 400px;
	}

	.slider-caption-wrap {
		position: static;
		top: 107px;
		display: block;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
		margin-top: 1rem;
		padding: 0rem 20px 2rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #3e3f3d;
	}

	.slider-arrow {
		top: 400px;
		bottom: auto;
		width: 4rem;
		height: 3rem;
		margin-top: -3rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #e4e7eb;
		font-size: 1.375rem;
	}

	.slider-indicator {
		top: 400px;
		bottom: auto;
		margin-top: -1em;
	}

	.button.slider-button {
		background-color: #163a59;
		color: #fff;
		font-size: 0.9375em;
	}

	.button.slider-button:hover {
		padding-left: 3.13rem;
		background-color: #3e3f3d;
		color: #fff;
	}

	.slider-caption-title-wrap,
	.slider-caption-all {
		padding: 0;
	}

	.slider-caption-all p {
		margin-top: 0em;
		margin-bottom: 0.75em;
		background-color: transparent;
		box-shadow: none;
		line-height: 1.2;
	}

	.flex-row._1 {
		display: block;
	}

	.right-sidebar-column .box {
		padding-right: 0px;
		padding-left: 0px;
		margin-left: 0px;
		margin-right: 0px;
	}

	.slider-section {
		margin-top: 209px;
	}

	.section-wrap.inside-content-section {
		margin-top: 209px;
	}

	.button.bill-tracker-button {
		font-size: 0.9375em;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-2 {
		max-width: 991px;
	}

	.cb-heading.clients-cb-head {
		font-size: 2.014rem;
		line-height: 2.714rem;
	}

	.w-slide {
		height: auto;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 0.875rem;
		line-height: 1.375rem;
	}

	h1 {
		font-size: 2.25em;
		line-height: 2.55rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2.3rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	h5 {
		font-size: 1.15rem;
		line-height: 1.6rem;
	}

	h6 {
		font-size: 1.0625em;
	}

	p {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	.footer-section a[href^="tel"] {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	.button.secondary.wishlist {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.button.header-search-button {
		width: 15%;
		height: 3.25rem;
		font-size: 1.125rem;
	}

	.button.slider-button {
		font-size: 1.0625em;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.nav-link {
		font-size: 1.125rem;
		letter-spacing: 0.04em;
	}

	.logo {
		margin-top: 0.25em;
		margin-bottom: 0.5em;
		margin-left: 0em;
		padding-left: 0px;
	}

	.mobile-menu-toggle-wrapper {
		display: inline-block;
		overflow: hidden;
	}

	.column.shop-detail-image-col {
		padding-right: 0px;
	}

	.column.footer-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: left;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0.5rem;
	}

	.logo-image {
		width: 200px;
	}

	.copyright-text {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.footer-signoff-grip {
		text-align: right;
	}

	.column.footer-signoff-column._1 {
		display: block;
		text-align: center;
	}

	.column.footer-signoff-column._1 > * {
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}

	.warning-p {
		text-align: center;
	}

	.warning-p a {
		margin-top: 5px;
		display: inline-block;
	}

	.footer-column-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 0rem;
	}

	.footer-column-wrap.footer-social-column {
		padding-top: 0rem;
		padding-bottom: 0.5rem;
	}

	.footer-column-wrap.footer-updates-column {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		border-right-style: none;
	}

	.header-content-right-wrap {
		margin-right: 1em;
	}

	.form-field.header-search-field {
		height: 3.25rem;
		font-size: 1em;
	}

	.slider-section {
		margin-top: 131px;
	}

	.secondary-nav-list {
		-webkit-box-flex: 0 1 50%;
		-webkit-flex: 0 1 50%;
		-ms-flex: 0 1 50%;
		flex: 0 1 50%;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		max-width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-image-example {
		max-width: 100%;
	}

	.secondary-nav-icon.idea-icon {
		width: 1.5em;
		height: 1.5em;
		margin-right: 0.375rem;
		margin-bottom: 0rem;
		padding: 3px 3px 5px;
	}

	.secondary-nav-list-link {
		padding-top: 2px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 520px;
	}

	.slider-arrow {
		top: 300px;
		bottom: auto;
		width: 3.25rem;
		margin-top: -3rem;
	}

	.slider-indicator {
		top: 300px;
		bottom: auto;
		margin-top: -1em;
		margin-bottom: 0em;
	}

	.home-image-link {
		height: 50vw;
		max-height: 300px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		max-height: 260px;
	}

	.home-content-box-wrap {
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap.intro-content-box-wrap {
		width: auto;
		margin-right: 10px;
		margin-left: 10px;
		float: none;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		margin-bottom: 1.5rem;
		padding-bottom: 3rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-content-box-wrap.hero-cbox-wrap.last-hero-cbox {
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.home-content-box-wrap.last-cbox-wrap {
		border-bottom-style: none;
	}

	.home-content-box-title {
		line-height: 1.125em;
	}

	.slider-image {
		position: relative;
		height: 300px;
	}

	.slider-caption-wrap {
		top: 0;
		max-width: 100%;
		margin: 20px 0 30px;
		padding-bottom: 0rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #373a3d;
	}

	.shop-item-wrap.flex {
		width: 48%;
	}

	.inside-page-header-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.inside-page-header-section {
		height: 60px;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.intro-text-style,
	.intro_text_style {
		font-size: 1.125em;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		width: 100%;
		margin-left: 0%;
		padding-top: 1.125em;
		float: left;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: none;
		font-size: 1.125rem;
		text-align: left;
	}

	.slider-caption-title {
		padding-top: 0.1875em;
		background-color: transparent;
		box-shadow: none;
		font-size: 2.5em;
		line-height: 1.125em;
	}

	.footer-social-icons-wrap {
		margin-bottom: 0em;
	}

	.footer-social-icon-image {
		width: 36px;
		height: 36px;
	}

	.home-cbox-dynamic-feed-item-wrap {
		width: 100%;
		margin-right: 0%;
	}

	.link.content-box-title-link:hover {
		border-bottom-color: transparent;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.pagination-wrap.flex-pagination {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.pagination-right-side-wrap {
		display: none;
	}

	.header-container {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.secondary-header-item-wrap {
		font-size: 1.125rem;
	}

	.secondary-header-item-cta-link {
		margin-left: 0rem;
	}

	.header-search-wrap {
		padding-bottom: 1px;
	}

	.shop-detail-attributes-wrap {
		margin-top: 1.5em;
	}

	.slider-caption-title-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.slider-caption-heading,
	.slider-caption-all h1,
	.slider-caption-all h2,
	.slider-caption-all h3,
	.slider-caption-all h4,
	.slider-caption-all h5,
	.slider-caption-all h6,
	.slider-caption-all p {
		color: #373a3d;
		text-shadow: none;
	}

	.intro-cbox-section {
		margin-top: 0em;
	}

	.footer-social-block {
		padding-left: 0rem;
	}

	.footer-notifications-image {
		width: 10vw;
		height: 10vw;
		margin-right: 5%;
		margin-left: 5%;
	}

	.content-image-example {
		width: 100%;
		margin-bottom: 15px;
		margin-left: 0px;
		float: none;
	}

	.flex-cb-box-text-wrap {
		height: auto;
		display: block;
	}

	.hero-cbox-wrap,
	.home-content-box-wrap {
		height: auto;
	}

	.slider-caption-all p {
		margin-top: 0em;
		background-color: transparent;
		box-shadow: none;
		color: #3e3f3d;
		font-size: 1.3rem;
		line-height: 1.8rem;
		text-shadow: none;
	}

	.home-cb-column-half {
		width: 100%;
	}

	.slider-section {
		margin-top: 187px;
	}

	.section-wrap.inside-content-section {
		margin-top: 187px;
	}

	.button.bill-tracker-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 2rem;
		padding-left: 3.25rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #5c7f06;
		color: #fff;
		font-size: 1.0625em;
	}

	.bill-tracker-icon {
		width: 3rem;
		padding-left: 0.125rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border-left: 1px solid hsla(0, 0%, 100%, 0.2);
		border-right-style: none;
		border-right-color: hsla(0, 0%, 100%, 0.2);
		background-color: transparent;
		background-image: url("../images/capitol-building-01.svg");
		background-size: 26px 26px;
	}

	.container-2 {
		max-width: 767px;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 0rem;
	}

	.cb-heading.clients-cb-head {
		line-height: 2.714rem;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 2em;
		line-height: 2.3rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.button.secondary.wishlist {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.button.header-search-button {
		width: 20%;
		height: 3rem;
	}

	.button.add-to-cart {
		max-width: 100%;
		margin-right: 0em;
	}

	.button.slider-button {
		font-size: 1em;
	}

	.logo {
		right: 0.375em;
		margin: 0.25em auto 0.5em;
		font-size: 1rem;
	}

	.row.footer-signoff-row {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column.footer-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.logo-image {
		width: 180px;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.copyright-text {
		margin-left: 0.5rem;
		text-align: center;
	}

	.footer-signoff-list {
		margin-top: 0.75rem;
		text-align: center;
	}

	.footer-signoff-list-item {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		margin-left: 1rem;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		text-align: center;
		display: block;
	}

	.footer-contact-wrap + .footer-contact-wrap {
		margin-top: 30px;
	}

	.footer-column-wrap.footer-updates-column {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
		text-align: center;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.header-search-field {
		height: 3rem;
	}

	.secondary-nav-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		max-width: 200px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.secondary-nav-icon {
		margin-top: 0em;
		margin-right: 0rem;
	}

	.secondary-nav-icon.contact-us-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.secondary-nav-list-link {
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-arrow {
		top: 230px;
		font-size: 1.25rem;
	}

	.slider-indicator {
		top: 230px;
		margin-top: -1em;
	}

	.home-image-link {
		height: 45vw;
		min-height: 120px;
	}

	.home-image-link.large-image-link {
		min-height: 180px;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 1.5625em;
	}

	.slider-image {
		height: 230px;
	}

	.shop-item-wrap.flex {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.menu-button-text {
		display: none;
	}

	.home-cbox-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.slider-caption-title {
		font-size: 2em;
	}

	.footer-social-icons-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-social-icon-link {
		margin-right: 12px;
		margin-bottom: 0px;
		margin-left: 12px;
	}

	.footer-newsletter-wrap {
		width: 100%;
		padding-left: 0px;
		float: none;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.pagination-wrap.flex-pagination {
		padding-right: 0%;
		padding-left: 0%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.pagination-left-side-wrap {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.shop-detail-form-buttons-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-acrobat-link {
		margin-right: 0rem;
		margin-bottom: 1.5rem;
		margin-left: 1rem;
	}

	.footer-social-block {
		text-align: center;
		margin-bottom: 30px;
	}

	.footer-contact-wrap {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		width: 100%;
	}

	.footer-notifications-image {
		width: 15vw;
		height: 15vw;
		margin-bottom: 2vw;
	}

	.slider {
		min-height: 450px;
	}

	.slider-caption-all p {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	.button.bill-tracker-button {
		font-size: 1em;
	}

	.home-content-section {
		padding-top: 1rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-2 {
		max-width: none;
	}

	.slider-section {
		margin-top: 173px;
	}

	.section-wrap.inside-content-section {
		margin-top: 173px;
	}
}

/* sticky header */
.sticky .logo {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
}

.sticky .logo-image {
	width: 160px;
}

.header-mobile-search-reveal {
	opacity: 0;
	max-height: 0;
	transition: all 300ms ease;
}

.header-mobile-search-reveal.show {
	max-height: 53px;
	opacity: 1;
}

.nav-search-button {
	display: none;
}

@media (max-width: 767px) {
	.nav-search-button {
		display: inherit ;
	}

	.secondary-nav-list-item.nav-search-button .secondary-nav-icon {
		margin-right: 0;
	}

	.sticky .logo-image {
		width: 150px;
	}

	.secondary-nav-text {
		font-size: max( 2.35vw, 1rem );
	}
}

html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.footer-section a[href^=tel] {
	text-decoration: none;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input::-webkit-input-placeholder {
	color: #b4bfcc !important;
	opacity: 1;
}

input::-moz-placeholder {
	color: #b4bfcc !important;
	opacity: 1;
}

input:-moz-placeholder {
	color: #b4bfcc !important;
	opacity: 1;
}

input:-ms-input-placeholder {
	color: #b4bfcc !important;
	opacity: 1;
}

.blockstyle-heading {
	-webkit-box-decoration-break: clone;
}

.skip-to-main-content.sronly {
	padding: 0 !important;
	height: 0px;
}

.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.375rem 1rem !important;
	box-shadow: 0 0 5px #000;
	z-index: 200;
}

/*mmenu*/
.mm-menu_offcanvas {
	min-width: 0;
	max-width: none;
}

.mobile-navigation-menu.mm-menu_opened {
	display: block !important;
	max-width: none;
}

.mobile-navigation-menu {
	display: none !important;
}

.w-nav.mm-slideout {
	z-index: 1;
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-opened {
		display: block !important;
		width: 80% !important;
		margin: 0;
		max-width: none;
		min-width: 0;
	}
}

html.mm-opening .mm-slideout {
	transform: translate(80%, 0px);
}


@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
	-webkit-transform: translate(80%, 0);
	-ms-transform: translate(80%, 0);
	transform: translate(80%, 0);
	-webkit-transform: translate3d(80%, 0, 0);
	transform: translate3d(80%, 0, 0);
}

/*remove default link styles*/
.mobile-navigation-menu {
	background-color: #163a59;
}

.mm-listview > li > a,
.mm-listview > li > span {
	margin-bottom: 1px;
	padding: 1rem 1rem 0.875rem;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff !important;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: left;
	text-transform: capitalize;
	font-family: 'Roboto Condensed', sans-serif;
}

.mm-menu .mm-listview > li.mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listview > li.mm-listitem_selected > span {
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: inset 5px 0 0 0 #5c7f06, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

.mm-listview > li > a:not(.mm-btn_next):hover,
.mm-menu .mm-listview > li > span:hover {
	background-color: #102b43;
	cursor: pointer;
}

/*remove default borders*/
.mm-listview > li:not(.mm-divider)::after {
	border-bottom-width: 0;
}

/*next links*/
.mm-listview > li .mm-btn_next::before {
	border-left-color: transparent;
	border-left: 1px solid hsla(0, 0%, 100%, 0.25) !important;
}

.mm-listview .mm-btn_next {
	width: 20% !important;
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.1);
	margin-bottom: -1px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
}

.mm-listview .mm-btn_next:hover {
	background-color: #102b43;
}

.mm-listview .mm-btn_next + .nav-link {
	width: 80%;
	margin-right: 20%;
}

/*next link arrow*/
.mm-btn_next::after,
.mm-arrow::after {
	right: 45%;
	height: 11px;
	width: 11px;
}

.mm-menu .mm-listview > li .mm-btn_next::after,
.mm-menu .mm-listview > li .mm-arrow::after {
	border-color: hsla(0, 0%, 100%, 0.5);
}

/*second level and previous links*/
.mm-prev::before {
	left: 17px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -8px;
}

.mm-navbar {
	background-color: rgba(0, 0, 0, 0.25);
	height: 52px;
	box-shadow: none;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
	margin-left: 0;
	margin-right: 0;
	color: hsla(0, 0%, 100%, 0.55);
	text-align: left;
}

.mm-navbar .mm-btn {
	height: 52px;
}

.mm-menu .mm-navbar .mm-btn::before,
.mm-menu .mm-navbar .mm-btn::after {
	border-color: hsla(0, 0%, 100%, 0.5);
}

.mm-navbar .mm-navbar__title {
	color: hsla(0, 0%, 100%, 0.55) !important;
	padding: 1em 0;
	font-size: 1.125rem;
	line-height: 1.125rem;
	text-align: left;
	font-weight: 500;
	font-family: 'Roboto Condensed', sans-serif;
	text-transform: capitalize;
}

.mobile-navigation-menu li.active .nav-link {
	background-color: rgba(0, 0, 0, 0.15);
	box-shadow: inset 5px 0 0 0 #5c7f06, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 992px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 768px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/*inside pages*/
/*messages*/
#session-message-wrapper .success {
	display: block;
	background-color: #1f8927;
	text-align: left;
	padding: 1.25rem;
	margin-bottom: 1.25rem;
	margin-top: 1.25rem;
	color: #ffffff;
}

#session-message-wrapper .error {
	display: block;
	background-color: #d11616;
	text-align: left;
	padding: 1.25rem;
	margin-bottom: 1.25rem;
	margin-top: 1.25rem;
	color: #ffffff;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 400;
	color: #ffffff;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(255, 255, 255, 0.75);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0;
	color: white;
	background-color: #373a3d;
	border-bottom: 2px solid white;
	padding: 0.625rem;
	text-align: left;
}

table.styled th {
	font-weight: normal;
	letter-spacing: 0;
	padding: 0.625rem;
	background-color: #163a59;
	border-bottom: 0;
	color: white;
	text-align: left;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom-width: 0px;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375em 0.625rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebedf0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0 none;
	}
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875em;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #878787;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: #e6e6e6 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #e6e6e6;
	border: 0px;
	color: #e0dfdc;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*right sidebar*/
.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}
